import Axios from "axios"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Button from "../components/button"
import FullScreenText from "../components/fullScreenText"
import Layout from "../components/layout"
import LoaderContainer from "../components/loaderContainer"
import { colors, db } from "../utils/siteVars"

const StyledSuccessPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 55px - 2rem);
`

const SuccessPage = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const search = window.location.search.substring(1)
    if (search.length > 0) {
      const body = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
      console.log(body)
      Axios.post(db + "orders/addcustomimage", body)
        .then(() => {
          // setLoading(false)
          window.location.search = ""
        })
        .catch(err => {
          console.log(err)
        })
    } else setLoading(false)
  }, [])

  const query = useStaticQuery(graphql`
    query successQuery {
      bgImg: file(relativePath: { eq: "bg_make.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const c = "rgba(30,50,100,0.8)"
  const layeredBG = [
    `linear-gradient(${c}, ${c})`,
    query.bgImg.childImageSharp.fluid,
  ]
  return (
    <Layout title="Success" bg={layeredBG}>
      <LoaderContainer active={loading} />
      <StyledSuccessPage>
        <FullScreenText>
          <h1>
            Your order <br />
            <span>was placed!</span>
          </h1>
          <p>You will shortly recieve a confirmation email.</p>
          <div className="button-container">
            <Link to="/">
              <Button bg={colors.secondary} color={colors.dark}>
                Back to home
              </Button>
            </Link>
          </div>
        </FullScreenText>
      </StyledSuccessPage>
    </Layout>
  )
}

export default SuccessPage
